<template>
  <div class="intro">
    <div class="info-container">
    </div>
  </div>
</template>

<script>

import Cuidadano from '../assets/images/cuidadano.png'

export default {
  name: "Intro",
  Cuidadano,
};
</script>

<style lang="scss">
.intro {
  min-height: 500px;
  background-image: url("~@/assets/image.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  .info-container {
    min-height: 300px;
    background-image: url("~@/assets/images/cuidadano.png");
    padding: 4rem 15rem;
    margin-left:10%;
    margin-right: auto;
  }
}
</style>
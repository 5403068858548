<template>
  <div style="background-color: #fafafa">
    <v-container>
      <div class="coverage">

        <div class="rectangulo">
          <h1
            style="font-size: 20px; text-align:center; color:white; display: block; margin-left: auto; margin-right: auto; ">
            <font face="Nunito">Por 12 meses para contrataciones del <strong style="color:#19E9CC;">Plan
                Full</strong> de
              Accidentes Personales y Hogar hasta el 30 de noviembre de 2022.</font>
          </h1>
        </div>

        <div class=" rectangulo2">
          <li style="color: #878787">
            <font face="Nunito">Incluye asistencia de <strong>telemedicina</strong>, <strong>legal</strong> y
              <strong>psicológica</strong>,
              además de <strong>asistencia al hogar</strong>, plomería,
              electricista y ¡mucho más!.
            </font>
          </li>
          <li style="color: #878787">
            <font face="Nunito">Desde $6.690 mensuales (UF 0,20 - valor en UF
              al momento de contratar el seguro)</font>
          </li>
          <li style="color: #878787">
            <font face="Nunito">Renovación automática anual.</font>
          </li>
        </div>
        <div>
          <p style="font-size: 15px; color: #878787; margin-left: 55%;">
            <font face="Nunito">*Valores en UF a la fecha
              de contratación.</font>
          </p>
        </div>

        <v-row style="justify-content:center;">
          <v-btn color="#5057B3" style="color: #19E9CC" @click="transfer()"> Quiero cotizar
          </v-btn>
        </v-row>

      </div>

    </v-container>
  </div>
</template>

<script>

export default {
  name: "Coverage",
  data: () => ({
  }),
  methods: {
    transfer() {
      window.location.href = "/cotizador"
    }
  }
};
</script>

<style lang="scss">
.coverage {
  padding: 4rem 0;

  .rectangulo {
    max-width: 650px;
    border: 3px solid #555;
    background: #5057B3;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }

  .rectangulo2 {
    max-width: 650px;
    text-align: center;
    border: 3px solid #878787;
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }
}
</style>
<template>
  <v-container>

    <h1
      style="font-size: 30px; text-align:center; color:#5057B3; display: block; margin-top: -15%; margin-left: auto; margin-right: auto; max-width: 400px;">
      <font face=”Nunito”>¡Asegurate contra accidentes y
        también protege
        a tu hogar!</font>
    </h1>
    <h1
      style="font-size: 28px; text-align:center; color:#ABA2E4; display: block;">
      <font face="Nunito">Seguro para extranjeros</font>
    </h1>

    <div class="benefits">
      <div class="benefit-detail">
        <div class="circulo">
          <br>
          <h1
            style="text-align:center; color:white; display: block; margin-left: auto; margin-right: auto;">
            <font face="Nunito">Desde <p><strong style="color:#19E9CC; font-size: 115%;">$12.990</strong></p> mensuales
              <p>(UF 0,40)</p>
              </font>
          </h1>
        </div>
      </div>
      
      <div class="benefit-detail">
        <div class="circulo">
          <br>
          <br>
          <h1
            style="text-align:center; color:white; display: block; margin-left: auto; margin-right: auto;">
        
            <font face="Nunito"><strong style="color:#19E9CC; font-size: 150%;">50%
              </strong>Off de descuento de la prima</font>
          </h1>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Benefit1 from "@/assets/images/Benefit1.svg";
import Benefit2 from "@/assets/images/Benefit2.svg";
import Benefit3 from "@/assets/images/Benefit3.svg";
import Benefit4 from "@/assets/images/Benefit4.svg";

export default {
  name: "Benefits",
  data: () => ({
    Benefit1,
    Benefit2,
    Benefit3,
    Benefit4,
  }),
};
</script>

<style lang="scss">
.benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 0;
  flex-wrap: wrap;
  justify-content: center;

  .benefit-detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }

    h4 {
      text-align: center;
      font-weight: normal;
    }
  }
}

.circulo {
  width: 300px;
  height: 300px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 100%;
  border: 20px solid #e6e5e5;
  background: #5057B3;
}
</style>
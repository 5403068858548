<template>
  <v-app>
    <v-app-bar color="#211C3C" light elevation="0" height="75">

      <nav class="nav">
        <router-link to="/" style="display: flex;align-items: center;justify-content: left;">
          <img :src="LogoMercer" alt="logo">
        </router-link>
        <div class="nav_opt">
          <div class="options">
            <router-link to="/">
              <a href="#">Home</a>
            </router-link>
      
            <router-link to="/cotizador">
              <a href="#">Contrátalo</a>
            </router-link>
      
          </div>
        </div>
      
      </nav>

      <!-- <router-link :to="{ path: '/' }">
        <div>
          <v-btn>HOME</v-btn>
        </div>
      </router-link>

      <router-link :to="{ path: '/cotizador' }">
        <div>
          <v-btn>CONTRÁTALO</v-btn>
        </div>
      </router-link> -->

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LogoMercer from "@/assets/images/SB_logo.png";

export default {
  components: {
    Footer,
  },
  data: () => ({
    LogoMercer,
  }),
};
</script>

<style lang="scss">
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  top: 0;
  width: 100%;
  padding: 1.5rem 0;
  background-color: #211C3C;

  img {
    width: 18%;
    margin-left: 5rem;
  }

  .nav_opt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5%;
    width: 35%;

    .options {
      padding-right: 5%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .hidden {
        display: none;
      }

      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 15px;
        margin-right: 0.8rem;

        &:hover {
          color: #19E9CC
        }

      }

    }

    .button {
      background: #19E9CC;
      border: 1px solid #AFC0FA;
      color: #61646F;
      transition: background 1s, border-color 1s;
      width: 100px;

      &:hover {
        background: #AFC0FA;
        border-color: #fff;
      }
    }
  }
}
</style>

<template>
  <div style="background-color: #211C3C">
    <div style="background-color: #211C3C; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div class="footer-element">
          <v-img contain max-height="165px" :src="LogoMigrantes" max-width="340px" />
        </div>

        <div class="footer-element" style="min-width: 150px">
          <v-col class="ma-0 pa-0">
            <div style="height: 100%">Síguenos en</div>
            <v-row class="ma-0 pa-0">
              <v-btn class="ma-2" text icon color="#FFFF" href="#" target="_blank">
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
              <v-btn class="ma-2" text icon color="#FFFF" href="#" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn class="ma-2" text icon color="#FFFF" href="#" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn class="ma-2" text icon color="#FFFF" href="#" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <h3 class="ma-3">@southbridgechile</h3>
            </v-row>
            <h1>
              <font face="Nunito" style="font-size:90%">www.southbridgeseguros.cl</font>
            </h1>
          </v-col>

        </div>
        
      </v-layout>

      <div class="footer-element2">
      
        <li text icon color="#FFFF" href="#" target="_blank">
          <v-icon color="#FFFF">mdi-map-marker-radius</v-icon>
          Av. Presidente Riesco 5335, Piso 15, Las Condes
        </li>
        <li text icon color="#FFFF" href="#" target="_blank">
          <v-icon color="#FFFF">mdi-email-outline</v-icon>
          <a class="ln" style="color:white" href="email:contacto@sbins.cl">
            contacto@sbins.cl
          </a>
        </li>
        <li text icon color="#FFFF" href="#" target="_blank">
          <v-icon color="#FFFF">mdi-phone</v-icon>
          <a class="ln" style="color:white" href="tel:+562800200802">
            800 200 802
          </a>
        </li>
        <li text icon color="#FFFF" href="#" target="_blank">
          <v-icon color="#FFFF">mdi-cellphone</v-icon>
          <a class="ln" style="color:white" href="tel:+56228268000">
            +562 2826 8000
          </a>
        </li>

      </div>

    </v-container>
    <footer class="footer">
      <section style="color:white">
        © {{year}} Powered By &thinsp;<a style="color:white" href="https://embedx.io/"> EmbedX</a>
      </section>
    </footer>
  </div>

</template>

<script>
import LogoMigrantes from "@/assets/images/SB_logo.png";

export default {
  name: "Footer",
    mounted(){
      var currentTime = new Date();
      var year = currentTime.getFullYear()
      this.year = year;
      console.log(this.year)
    },
  data: () => ({
    year: null,
    LogoMigrantes,
  }),
};
</script>

<style lang="scss" scoped>
//@import url("../styles/main.scss");

.footer-element {
  color: #FFFF;
  min-height: 100px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  .ln {
    color : #f49e15
  }
}

.footer-element2 {
  color: #ffff;
  justify-content: center;
  margin: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;

  li{
    margin: auto;
    
  }
}

.layout.wrap {
  justify-content: space-around;
}

.footer{
    section{
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0.7rem;
            div{
                padding: 0.5rem 0;
                display: flex;
                flex-direction: column;
                a{
                    text-decoration: none;
                    padding-bottom: 1rem;
                }
            }
        }
        .footer-contact{
            img{
                width: 60%;
            }
            div{
                display: flex;
                flex-direction: row;
                a{
                    padding-bottom: 0;
                }
                img{
                    width: 8%;
                    padding-right: 10px;
                }
            }
            .footer-contact-rrss{
                img{
                    width: 20%
                }
            }
        }
        &:last-child{
            background: #293379;
            padding: 10px;
            p{
                display: flex;
                margin: auto;
                color: #FFF;
                img{
                    margin: 3px;
                }
            }
        }
    }
}
</style>

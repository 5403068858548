<template>
  <div class="home">
    <v-alert v-if="success === true" dense text type="success">
      ¡Has contratado tu seguro con éxito! Revisa tu <strong>correo electrónico</strong> para obtener toda la información.
    </v-alert>
    <v-alert v-if="success === false" dense text type="error">
      Ha ocurrido un error al contratar tu seguro. Intenta nuevamente.
    </v-alert>
    <Intro />
    <Benefits />
    <Coverage />
    <!-- <Form :landing="landing" /> -->
  </div>
</template>

<script>
import Intro from "@/components/Intro.vue";
import Benefits from "@/components/Benefits.vue";
import Coverage from "@/components/Coverage.vue";
// import Form from "../views/Form.vue";

export default {
  name: "Home",
  components: {
    Intro,
    Benefits,
    Coverage,
    // Form,

  },
  data() {
    return {
      success: null,
      // landing: true,
      previewUserData: {}
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.success = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #F0F0F0;
}

.v-alert--text {
  margin-bottom: 0 !important; 
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}
</style>